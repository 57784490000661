$(document).ready(function() {

	function OpenWindow(targetWindow) {
		$(targetWindow).arcticmodal({
			closeOnEsc: false,
			closeOnOverlayClick: false,
			openEffect: {
				type: "fade",
				speed: 300
			},
			overlay: {
				css: {
					backgroundColor: "#010F2E",
					opacity: .80
				}
			}
		});
	}

	$(".js-order_button").click(function(){
		$(".js-bonus_window").fadeOut(300);
		OpenWindow(".js-order_window");
	});

	let spinCount = 2;

	$(".js-spin_button").click(function(){
		if (!$(this).hasClass("disabled")) {
			
			spinCount--;
			$(".js-count").text(spinCount);

			if (spinCount == 1) {
				$(".js-spin_button").addClass("disabled");
				$(".js-wheel").addClass("rotate1");
				setTimeout(function() {
					$(".js-spin_button").removeClass("disabled");
					$(".js-bonus_window").fadeIn(300);
				}, 5000);
			}

			if (spinCount == 0) {
				$(".js-spin_button").addClass("disabled");
				$(".js-wheel").addClass("rotate2");
				setTimeout(function() {
					$(".js-bonus_window").fadeOut(300);
					OpenWindow(".js-order_window");
				}, 8000);
			}

		}
	});

});

function showHidePassword(target) {
	let input = document.getElementById('password-input');
	if (input.getAttribute('type') == 'password') {
		target.classList.add('view');
		input.setAttribute('type', 'text');
	} else {
		target.classList.remove('view');
		input.setAttribute('type', 'password');
	}
	return false;
}

window.showHidePassword = showHidePassword;